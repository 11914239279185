/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
require("turbolinks").start()

import 'stylesheets/application'

global.preload = function(arrayOfImages) {
    $(arrayOfImages).each(function () {
        
        var img = $('<img />');
        img.appendTo('body').hide();
        img.attr('src',this);
    });

}
    
global.loadYoutubeVideo = function(url, autoplay, loop) {

    $('#banner').addClass('photoAndVideo');
    $('#banner').css('background-image','none');
    $('#banner').html('<iframe id="video" src="'+url+'?rel=0&showinfo=0&vq=hd1080'+((autoplay)?'&autoplay=1':'')+((loop)?'&loop=1':'')+'" frameborder="0" allowfullscreen></iframe>');

}


global.loadVimeoVideo = function(url, autoplay, loop) {
    
    $('#banner').addClass('photoAndVideo');
    $('#banner').css('background-image','none');
                    
    $('#banner').html('<iframe id="video" src="'+url+'?title=0'+((autoplay)?'&amp;autoplay=1':'')+((loop)?'&amp;loop=1':'')+'" frameborder="0" allowfullscreen></iframe>');
}

global.loadPhoto = function(url) {
    
    
    $('#banner').addClass('photoAndVideo');
    $('#banner').css('background-image','url("'+url+'")');
    $('#banner').html('');
}

global.loadPress = function(html) {
    
    $('#banner').addClass('press');
    $('#banner').css('background-image','none');
    $('#banner').html(html);
}
    
global.selectMedia = function(elt) {
    
    var type = elt.attr('type');
    
    
    if(type == 'ignore') return;
    
        var media = elt.attr('media');
        
        $('#banner').removeClass('press');
        $('#banner').removeClass('photoAndVideo');
        $('#apps #controls .control').removeClass('selected');
        elt.addClass('selected');
        
        if(type == 'photo') {
            
            loadPhoto(media);
        
        } else if(type == 'video') {
            
            var player = elt.attr('player');
            var auto = elt.attr('autovideo');
            var loop = elt.attr('loopvideo');
                                    
            if(player == 'youtube') {
                
                loadYoutubeVideo(media, (auto=="true"), (loop=="true"));
                
            } else {
                
                loadVimeoVideo(media, (auto=="true"), (loop=="true"));
            }   
            
        }
}

global.openAppStore = function(url) {

    window.location.href = url;
}

global.openPopup = function(title, url) {

    // popup blocked
    window.open(url,title, "status=no,location=no,toolbar=no,menubar=no,width=600,height=300,left=100,top=100");
} 